// config/config.js
export const config = {
  development: {
    apiUrl: "http://127.0.0.1:8000", // Local development API URL
  },
  staging: {
    apiUrl: "https://api-stage-saas.comm3dev.com", // Staging API URL
  },
  production: {
    apiUrl: "https://api-saas.comm3dev.com", // Production API URL
  },
};
