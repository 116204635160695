import API from "./ApiBase";

export const register = (payload) => {
  return API.post("/auth/register/", payload);
};

export const login = (payload) => {
  return API.post("/login", payload);
};

export const logout = () => {
  return API.post("/auth/logout/");
};

export const forgetPassword = (payload) => {
  return API.post(`/auth/password-reset/`, payload);
};

export const resetPassword = (payload) => {
  return API.post(`/auth/reset-password-confirm/`, payload);
};
