import axios from "axios";
import { config } from "../config.js";
import { toast } from "react-toastify";

export const API_URL = config[process.env.REACT_APP_ENVIRONMENT].apiUrl;

const handleErrors = (err) => {
  if (err && err.response) {
    if (err.response.status === 401) {
      toast.error("Unauthorized please login again");
      window.localStorage.removeItem("token");
      window.location.href = "/login";
    } else if (
      err.response.status === 400 &&
      err.response.data &&
      err.response.data.errors
    ) {
      let errors = err.response.data.errors;
      if (!Array.isArray(errors)) {
        errors = Object.values(errors);
      }

      errors.forEach((el) => {
        if (Array.isArray(el)) {
          el.forEach((error) => {
            if (error) {
              toast.error(error);
            }
          });
        } else {
          toast.error(el);
        }
      });
    } else if (err.response.status === 500) {
      toast.error("Something went wrong");
    } else if (err.response.status === 404) {
      console.log(err);
      const message = err?.response?.data?.message;
      if (message?.startsWith("The route api")) {
        return toast.error("Something went wrong");
      }
      toast.error(message || "Something went wrong");
    } else {
      const message = err?.response?.data?.message;
      toast.error(message || "Something went wrong");
    }
  }
  return Promise.reject(err);
};

const axiosInstance = axios.create({
  baseURL: `${API_URL}/api/v1`,
  timeout: 300000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
);

axiosInstance.interceptors.response.use(function (response) {
  return response;
}, handleErrors);

export default axiosInstance;
