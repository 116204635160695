import React from "react";
import Header from "../../components/Header";
import AuthHelper from "../../shared/helpers/AuthHelper";
import Footer from "../../components/Footer";
import { Navigate } from "react-router-dom";

const PrivateLayout = ({ component: Component, ...rest }) => {
  const authHelper = new AuthHelper();
  const isLoggedIn = authHelper.isLoggedIn();

  if (!isLoggedIn) {
    // Not logged in, redirect to the login page with the return URL
    return (
      <Navigate to={{ pathname: "/login", state: { from: rest.location } }} />
    );
  }

  return (
    <div className="c-private-layout">
      <Header isLoggedIn={isLoggedIn} />
      <main className="c-layout__main c-private-layout__main container-fluid">
        <Component {...rest} />
      </main>
      <Footer />
    </div>
  );
};

export default PrivateLayout;
