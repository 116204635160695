import React from "react";
import AuthHelper from "../../shared/helpers/AuthHelper";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Navigate } from "react-router-dom";

const AccountLayout = ({ component: Component, enableRedirect, ...rest }) => {
  const authHelper = new AuthHelper();
  const isLoggedIn = authHelper.isLoggedIn();

  if (enableRedirect && isLoggedIn) {
    // Redirect logic when enableRedirect is true and user is logged in
    // You can replace this logic or handle redirection as needed
    return <Navigate to={{ pathname: "/", state: { from: rest.location } }} />;
  }

  return (
    <div className="c-account-layout">
      <Header />
      <main className="c-layout__main c-account-layout__main container-fluid">
        <div className="row flex-column-reverse flex-md-row border-top">
          <div className="register col-12 col-md-12 pr-0 justify-content-center align-items-center">
            <Component {...rest} />
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default AccountLayout;
