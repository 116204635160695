/* eslint-disable no-unused-vars */
import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Loading from "./components/Loading";
import PrivateLayout from "./layouts/PrivateLayout";
import AccountLayout from "./layouts/AccountLayout/index";

const loading = Loading();

const Login = React.lazy(() => import("./containers/Login/index"));
const Vendors = React.lazy(() => import("./containers/Vendors/index"));
const Users = React.lazy(() => import("./containers/Users/index"));
const ForgetPassword = React.lazy(
  () => import("./containers/ForgetPassword/index"),
);
const ConfirmResetPassword = React.lazy(
  () => import("./containers/ConfirmResetPassword/index"),
);
const VendorStatistic = React.lazy(
  () => import("./containers/VendorStatistic/index"),
);

function App() {
  const [contentVisible, setContentVisible] = useState(false);

  useEffect(() => {
    setContentVisible(true);
  }, [contentVisible]);

  return (
    contentVisible && (
      <Suspense fallback={loading}>
        <BrowserRouter>
          <Routes>
            <Route
              exact
              enableRedirect={true}
              path="/login"
              element={
                <AccountLayout enableRedirect={true} component={Login} />
              }
            />
            <Route
              exact
              path="/forget-password"
              element={<AccountLayout component={ForgetPassword} />}
            />
            <Route
              exact
              path="/reset-password-confirm/:uidb64/:token"
              element={<AccountLayout component={ConfirmResetPassword} />}
            />
            <Route
              exact
              path="/"
              element={<PrivateLayout component={Vendors} />}
            />
            <Route
              exact
              path="/users"
              element={<PrivateLayout component={Users} />}
            />
            <Route
              exact
              path="/vendor-statistic"
              element={<PrivateLayout component={VendorStatistic} />}
            />
            <Route exact path="/*" element={<Navigate to="/" />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    )
  );
}

export default App;
