import React from "react";

const Footer = () => {
  return (
    <div className="footer text-center">
      <p className="py-4 mb-0 footer footer-rights-reserved">
        C3 (Community Care Connection) - Copyright &copy;{" "}
        {new Date().getFullYear()}. All Rights Reserved
      </p>
    </div>
  );
};

export default Footer;
