import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import AuthHelper from "../../shared/helpers/AuthHelper";
import c3Logo from "../../assets/images/c3-logo.png";
import "./index.scss";

const StyledDiv = styled("div")({
  height: 65,
  position: "fixed",
  zIndex: 10,
  backgroundColor: "#132843",
  boxShadow: "0 3px 3px #ccc;",
});

function Header() {
  const { pathname } = useLocation();
  const authHelper = new AuthHelper();
  const isLoggedIn = authHelper.isLoggedIn();
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  return (
    <StyledDiv
      className={`w-100 d-flex justify-content-between align-items-center`}
    >
      <div className="d-flex align-items-center">
        <div className="mx-2 mx-md-4">
          <Link href="/" className="font-weight-bold text-decoration-none">
            <img
              height={40}
              className="pt-2 pb-2 rounded mr-2"
              alt=""
              src={c3Logo}
            />
            <span className="text-white">Community Care Connection</span>
          </Link>
        </div>
      </div>
      <div className="d-flex justify-content-end align-items-center log-in w-25">
        {isLoggedIn ? (
          <>
            <div>
              <nav className="navigation">
                <button
                  className="hamburger"
                  onClick={() => setIsNavExpanded(!isNavExpanded)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="white"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                <div
                  className={
                    isNavExpanded
                      ? "navigation-menu expanded"
                      : "navigation-menu"
                  }
                >
                  <ul>
                    <li className={pathname === "/" ? "active" : ""}>
                      <div>
                        <Link to="/">Vendors</Link>
                      </div>
                    </li>
                    <li className={pathname === "/users" ? "active" : ""}>
                      <div>
                        <Link to="/users">Users</Link>
                      </div>
                    </li>
                    <li>
                      <div>
                        <Link onClick={() => authHelper.logout()} to={"/login"}>
                          Logout
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </>
        ) : (
          <div className="mx-2 mx-md-4">
            <Link to="/login" className="font-weight-normal text-white">
              Log in
            </Link>
          </div>
        )}
      </div>
    </StyledDiv>
  );
}

export default Header;
