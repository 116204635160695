import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./index.scss";
import { ToastContainer } from "react-toastify";

const THEME = createTheme({
  typography: {
    fontFamily: `"cerebrisans-regular", "Helvetica", "Arial", sans-serif`,
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThemeProvider theme={THEME}>
    <App />
    <ToastContainer
      autoClose={3500}
      hideProgressBar={true}
      bodyClassName={{ defaultClassName: "p-2" }}
    />
  </ThemeProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
