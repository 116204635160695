import moment from "moment";
import { register, login } from "../apis/AuthService";

export default class AuthHelper {
  registerFunc = async (payload) => {
    const { data } = await register(payload);
    if (data) {
      this.storeRequiredInfo(data);
      return data;
    }
  };

  loginFunc = async (payload) => {
    try {
      const { data } = await login(payload);
      if (data) {
        this.storeRequiredInfo(data.data);
        return data.data.user;
      }
    } catch (err) {
      console.log(err);
    }
  };

  storeRequiredInfo = (loginData) => {
    const { token, user } = loginData;
    this.setToken(token);
    // this.setExpiryTime(res['expires_in']);
    this.setUserDetails(user);
  };

  isLoggedIn = () => {
    const token = this.getToken();
    if (token === "undefined") return false;
    return token && !this.isTokenExpired(token);
  };

  isTokenExpired = () => {
    try {
      const exp = window.localStorage.getItem("expiryTime");
      if (moment(exp) < moment()) {
        this.logout();
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };

  setToken = (idToken) => {
    window.localStorage.setItem("token", idToken);
  };

  setExpiryTime = (time) => {
    const expireTime = moment().add(time, "seconds").toString();
    window.localStorage.setItem("expiryTime", expireTime);
  };

  setUserDetails = (userDetails) => {
    window.localStorage.setItem("userDetails", JSON.stringify(userDetails));
  };

  getUserId = () => {
    const userDetails = window.localStorage.getItem("userDetails");
    return parseInt(JSON.parse(userDetails).id, 10);
  };

  getUserDetails = () => {
    const userDetails = window.localStorage.getItem("userDetails");
    return JSON.parse(userDetails);
  };

  getToken = () => {
    return window.localStorage.getItem("token");
  };

  logout = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("expiryTime");
    localStorage.removeItem("userDetails");
    localStorage.removeItem("role");
  };
}
